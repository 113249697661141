import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import {Link} from "gatsby"

const ImpressumPage = () => (
    <Layout>
        <SEO title="Impressum, Kontakt, Datenschutzerklärung"/>
        <h1 style={{color: '#024873'}}>Impressum & Kontakt</h1>
        <p style={{color: '#595959'}}>Erik Möser<br/>
            Mainstraße 20<br/>
            64390 Erzhausen</p>
        <p style={{color: '#595959'}}>
            kontakt [at] holisent.de<br/>
            Tel.: +49 6150 5081320
        </p>
        <h2 style={{color: '#024873'}}>Haftungsauschluss</h2>
        <p style={{color: '#595959'}}><strong>Haftung für Inhalte</strong><br/>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
            eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
            entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <p style={{color: '#595959'}}><strong>Haftung für Links</strong><br/>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
            verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
            Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <p style={{color: '#595959'}}><strong>Urheberrecht</strong><br/>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
            auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
        <h2 style={{color: '#024873'}}>Datenschutzerklärung</h2>
        <p style={{color: '#595959'}}>
            <Link to="/datenschutz" className="link">
                Zur Datenschutzerklärung
            </Link>
        </p>
        <h2 style={{color: '#024873'}}>Streitschlichtung</h2>
        <p style={{color: '#595959'}}>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
            bereit: <a
                style={{color: '#024873'}} href="https://ec.europa.eu/consumers/odr"
                target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>. Unsere E-Mail-Adresse
            befindet sich unter „Kontakt“.
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.</p>
        <h2 style={{color: '#024873'}}>Sprachlicher Hinweis</h2>
        <p style={{color: '#595959'}}>
            Damit die Texte auf dieser Website gut lesbar sind, wird manchmal nur die männliche Form eines personenbezogenen Hauptwortes verwendet. Selbstverständlich ist in einem solchen Fall immer auch gleichberechtigt das weibliche Geschlecht gemeint.
            Außerdem versuche ich, wenn möglich, die Berufsbezeichnung Product Owner zu nutzen. Alle Produktmanager oder Projektleiter mögen mir verzeihen – Ihr seid ebenfalls angesprochen. Aber die englische Berufsbezeichnung ist geschlechtsneutral…
        </p>
        <h2 style={{color: '#024873'}}>Bildnachweis</h2>
        <p>Das auf der Startseite verwendete Bild ist von <a style={{color: '#024873'}} target="_blank" rel="noopener noreferrer" href="https://unsplash.com/@flysi3000">Simon Abrams auf Unsplash</a>.</p>
    </Layout>
);

export default ImpressumPage
